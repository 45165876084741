import React from "react";

import {
  
  Card,
  CardImg,
 
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";


export default function Internships() {
 
  const data = [
    {id:1,Position:"Full Stack developer (React JS)", Description:" Date:  06 February 2022, Brik + Clik is a NYC based early stage startup that is building out its A team to resurrect the retail apocalypse. We design and build inviting retail spaces, curate the most curious brands and operate a sustainable space. We are not a pop up but a permanent technology based solution. We are looking for a Full Stack developer who is proficient with React.js. The primary focus of the selected candidate would be on developing user interface components implementing and executing them following well-known React.js workflows (such as Redux).Requirements Experience with building responsive, visual, interactive web-based user interfaces Thorough understanding of React.js and its core principles (Class and Functional Components) Working on Backend Experience with AWS is a plus Extensive knowledge with HTML / CSS(SASS) Experience with common front-end development tools such as Babel, Webpack, NPM, etc. Interest in 3D Technologies (WebGL, ThreeJS) Working knowledge of Git (Gitlab experience a plus) Team player Great interpersonal and communication skills Excellent time-management skills Accurate Task Estimation", img:"hiringFullStack.png", Apply:"mailto:hemant@brikclik.com"},
    {id:2,Position:"Software Developer ", Description:"  Date:  29 september 2021, Paid Internship opening at TECHNICRAFTS ENVIRO SOLUTIONS for the role of software developer and Django developer with the perks of job offer and certificate of internship Selected intern's day-to-day responsibilities includes Write code to implement visual elements into various web applications, Create prototypes, mockups, and staging sites for review and feedback,Work on contributing to responsive front-end, Work on making the use of APIs from third parties to enhance the capabilities of the platform",img:"internship3.png", Apply:"mailto:hr@technicrafts.in"},
    {id:3,Position:"Machine Learning", Description:" Date:  23 september 2021, Paid Internship  opportunity  provided by  ATS LEARNING SOLUTIONS For the role of Machine learning developer with the perks of certificate of internship . Selected intern's day-to-day responsibilities includes Design and Develop statistical models that can solve the business problem, Work on the evaluation of different models for the best fit, Work will cover sourcing data from multiple databases, cleaning, and transformation ", img:"internship1.jpeg", Apply:"http://www.atsls.com/"},
  ]

  const renderList = data.map((item)=>{
    return(
      <Row className="row-grid justify-content-between" key={item.id}>
                    <Col md="6">
                    <br /><br/> 
                      <div className="pl-md-5">
                        <h1 style={{fontSize:"35px"}}>
                          {item.Position}
                        </h1>
                        <p style={{fontSize:"15px", textAlign: 'justify', textJustify: 'inter-word'}}>
                          {item.Description}
                        </p>
                      
                        <br />
                        <a
                          className="font-weight-bold text-info mt-5"
                          href={item.Apply}
                          target="_blank"
                          style={{fontSize:"18px"}}
                        //   onClick={(e) => e.preventDefault()}
                        >
                         Apply{" "}
                          <i className="tim-icons icon-minimal-right text-info" />
                        </a>
                      </div>
                    </Col>
                    
                    <Col lg="6" md="6">
                      <Card>
                        <CardImg top width="10%" src={item.img}/>
                      </Card>
                    </Col>
      </Row>
    )
  })   

  return (
    <>
      <ExamplesNavbar />
      <Container style={{marginTop:100}}>
         
                    
                {renderList}

        
      </Container>
      <Footer/>
    </>
  );
}
