import React, {useEffect, useRef,  useState} from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Lottie from "lottie-web";
import classnames from "classnames";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import codecell from "../../assets/img/codertine/CodeCellLogo.png";
import cess from "../../assets/img/codertine/Cess.png";
import TimelineObserver from "react-timeline-animation";
import CodertineTimeline from "./CodertineTimeline";
import codingBlock from "../../assets/img/codertine/codingBlock.png"
import defaultImg from "../../assets/img/default-image.jpg"
import abner from "../../assets/img/codertine/abnerSec.png"
import recursionLogo from "../../assets/img/recursion/Recursion-Logo.png"
import iccLogo from "../../assets/img/recursion/IccLogo.png";
import veefly from "../../assets/img/recursion/veefly.jpeg";
import promodome from "../../assets/img/recursion/promodome.jpeg";
import syb from "../../assets/img/recursion/syb.jpeg";
import interview from "../../assets/img/recursion/interview.jpeg";
import soe from "../../assets/img/recursion/soe.jpeg";
import man from "../../assets/img/recursion/man.jpeg";
import taskade from "../../assets/img/recursion/taskade.jpeg";
import w from "../../assets/img/recursion/w.jpeg";
import rogue from "../../assets/img/recursion/rogue.png";
import hover from "../../assets/img/recursion/hover.png";
import echo from "../../assets/img/recursion/echo.jpeg";
import revup from "../../assets/img/recursion/revup.svg";

import axure from "../../assets/img/recursion/axure.jpeg";
import mindflix from "../../assets/img/recursion/mindflix.jpeg";
import singh from "../../assets/img/recursion/singh.jpeg";

import waffle from "../../assets/img/recursion/food.jpeg";
import interviewBuddy from "../../assets/img/recursion/interviewbuddy.png"
import rosenfeld from "../../assets/img/recursion/rosenfeld.png"
import internetivo from "../../assets/img/recursion/internetivo.png"
import gfg from "../../assets/img/codertine/gfg.png"
// import pdf from "./brochure.pdf"
import RecursionBrochure from "./RecursionBrochure.pdf"
import Clock from "./Clock";



import devfolio from "../../assets/img/recursion/devfolio.png"
import polygon from "../../assets/img/recursion/polygon.png"
import codingNinja from "../../assets/img/recursion/coding-ninja.png"
import filecoin from "../../assets/img/recursion/filecoin.png"
import celo from "../../assets/img/recursion/celo.png"
import tezos from "../../assets/img/recursion/tezos.png"
import wolfram from "../../assets/img/recursion/Wolfram.png"
import xyz from "../../assets/img/recursion/xyz.png"
import aops from "../../assets/img/recursion/aops.png"
import mentor from "../../assets/img/recursion/mentor.png"
import replit from "../../assets/img/recursion/replit.png"
import solana from "../../assets/img/recursion/solana.png"
import codingMin from "../../assets/img/recursion/codingMin.jpg"
import hoodzilla from "../../assets/img/recursion/hoodzilla.jpg"

import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaCalendarAlt } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa';
import { FaMoneyBill } from 'react-icons/fa';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";



// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";

// Stats Component
import PrevYearStat from "./PrevYearStat"
import statData from "./StatsData"

// Domain Component
import Domain from "./Domain"

// Schedule Component
// import Schedule from "./Scheduling"

const carouselItems = [
  
  {
    src: require("assets/img/recursion5.0.jpg").default,
    altText: "",
    caption: "",
  }, {
    src: require("assets/img/codertine/cash.jpg").default,
    altText: "Cash Prize",
    caption: "Win Cash Prizes",
  },
  {
    src: require("assets/img/codertine/certf.png").default,
    altText: "Certficate",
    caption: "Earn Certificates",
  },
];


export default function Recursion() {
  const [tabs, setTabs] = React.useState(1);
  let ps = null;
  const spaceship = useRef(null)
  const progGuy = useRef(null)
  useEffect(()=>{
    Lottie.loadAnimation({
      container: progGuy.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../Lottie/progGuy.json')
    })
  }, [])
  useEffect(()=>{
    Lottie.loadAnimation({
      container: spaceship.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../Lottie/spaceship.json')
    })
  }, [])

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    
  },[]);

  const astronaut = useRef(null)


  useEffect(()=>{
    Lottie.loadAnimation({
      container: astronaut.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../Lottie/astronaut.json')
    })
  }, [])

  /* Clock Starts*/

  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();

  let interval;

  const startTimer = () => {
    const countDownDate = new Date("March 13,2024").getTime();
    interval = setInterval(() => {
      const now = new Date().getTime();

      const distance = countDownDate - now;

      const days = Math.floor(distance / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        // Stop Timer

        clearInterval(interval.current);
      } else {
        // Update Timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  useEffect(() => {
    startTimer();
  });

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apply.devfolio.co/v2/sdk.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
}, []);
  /* Clock Ends */
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper" style={{backgroundColor:"black"}}>

               
               
                            {/* <h1 className="text-white" className="codertine-head">
                            CODER<span className="text-success">TINE</span> <br /><br/>
                            </h1> */}
                        
                            {/* <img src={recursionLogo} alt="recursionLogo"  className=""/> */}
                              

                    {/* <Col lg="6" md="6">
                      <div ref={astronaut }>

                      </div>
                    </Col>  */}
                    

                    <Container id="top">
                    <Row>
                        <Col>
                        <img src={recursionLogo} alt="recursionLogo"  className=".recursionLogo-logo headTop"/>
                        </Col>
                        <Col lg="12">
                            <p className="text-white header-quote center" style={{textAlign:"center"}} >
                            {/* Recursion 3.0 is here again to bring out the coding spirit in you. */}
                            </p>

                            {/* Devfolio button */}
                            {/* <button id="" style={{textTransform:"none", marginLeft:"auto", marginRight:"auto", display:"block", marginTop:"20px", backgroundColor:"black", border: 'none'}}>
                            
                            <div 
                              class="apply-button" 
                              data-hackathon-slug="recursion-3-f2bb" 
                              data-button-theme="light"
                              style={{height:"44px", width: "312px"}}
                            ></div> 
                            </button> */}

<a href="https://discord.gg/beHt7aWvAw">
  <button id="" style={{textTransform:"none", marginLeft:"auto",fontSize:'20px',padding:'6px 25px 6px 25px', marginRight:"auto", display:"block", marginTop:"20px", backgroundColor:"white",color:'black', border: 'none'}}>
   Discord  </button>
  </a>

                            
                            <h1 style={{textAlign:"center", paddingTop:"50px", fontSize:"25px"}}>Hackathon begins on 13th March, 2023 - Reporting time @ 8:30 am</h1>
                            <h1 style={{textAlign:"center", fontSize:"25px"}}>(Members of selected teams expected to reach college by 8:30 am)</h1>
                          
                            <div className="App ClockTime center">
                            <Clock
                                timerDays={timerDays}
                                timerHours={timerHours}
                                timerMinutes={timerMinutes}
                                timerSeconds={timerSeconds}
                            />
                            </div>

                            <a href="https://forms.gle/sjnKo8jr2P3Gk59Z6" target="_blank">
                              <button style={{textTransform:"none", marginLeft:"auto",fontSize:'30px',padding:'10px 25px 10px 25px', marginRight:"auto", display:"block", marginTop:"40px", backgroundColor:"#3770ff",color:'white', border: 'none', borderRadius: '10px'}}>Register Here</button>
                            </a>
                            
                            <div className="btn-wrapper mb-3" style={{textAlign:"center"}}>
                                <Button
                                    className="btn-link"
                                    color="success"
                                    size="sm"
                                    style={{scrollBehavior:"smooth"}}
                                >
                                {/* <Link
                                    activeclassName="active"
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                <br/>
                                <p className="category text-success d-inline start">
                                    Let's Explore
                                </p>
                                    <i className="tim-icons icon-minimal-right" />
                                </Link> */}
                                </Button>
                            </div>
                        </Col>
                    </Row>

        </Container>
        {/* <Clock /> */}
        
        <div className="page-header">
          
          <Container className="align-items-center" id="about">
            <Row>
            <Col lg="6" md="6">
                <h1 className="profile-title text-left">About</h1>
                <h5 className="text-on-back">01</h5>
                <p className="aboutCod" >
                Recursion 5.0 is the annual hackathon organized by RGIT CodeCell and CESS, Rajiv Gandhi Institute of Technology, Mumbai. It is an in-person 24-hr event for innovatively fostering real-world problem-solving skills.
                <ul>
                    <li>Date: 13th March to 14th March 2023 </li>
                    <li>Team Size: 2 or 4 members </li>
                    <li>Free registrations</li>
                    <li>Participation is absolutely free, however we request you not to back out after getting shortlisted</li>
                    <li>Prizes worth Rs. 141K, Swags, vouchers and lot more!  </li>
                </ul>
                </p>
                
                
                               
              </Col>
              <Col lg="6" md="6">
                {/* <img src={codecell} alt="DSA"  className="codecell-logo"/> 
                <h1 style={{textAlign:"center"}}>&</h1><br/>
                <img src={cess} alt="DSA"  className="codecell-logo"/>            */}
                    <div ref={progGuy}></div>

              </Col>
              
            </Row>
           
            
          </Container>
        </div>

        <div className="btn-wrapper pt-3" style={{fontSize: '60px', textAlign:'center'}}>
                  {/* <Button
                    className="btn-simple"
                    color="primary"
                    href={RecursionBrochure}
                    target="_blank"
                    style={{fontSize: '30px'}}
                  >
                    
                    <i className="tim-icons icon-book-bookmark" style={{fontSize: '30px'}} /> Brochure
                  </Button> */}
                  <Button
                    className="btn-simple"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    style={{fontSize: '30px'}}
                  >
                  <Link
                    activeclassName="active"
                    to="rules"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <i className="tim-icons icon-bulb-63" style={{fontSize: '30px'}} /> Rules !
                  </Link>
                  </Button>
                </div>

        <Container style={{paddingBottom:'60px', paddingTop:'60px'}}>
          <section className="infoPanel">
            <div class="container">
            <div class="row inforation-wrapper">
              <div class="col-lg-3 col-md-6 col-xs-12" >
                <ul style={{listStyleType:'none'}}>
                  <li style={{fontSize:'50px'}}>
                    <FaMapMarkerAlt />
                  </li>
                  <li><span><b>Mode of Conduct:</b> <br/> Offline</span></li>
                </ul>
              </div>
              <div class="col-lg-3 col-md-6 col-xs-12">
                <ul style={{listStyleType:'none'}}>
                  <li style={{fontSize:'50px'}}>
                    <FaCalendarAlt/>
                  </li>
                  <li><span><b>Date &amp; Time:</b> <br/> 13th March, 2023 at 08:30 Am</span></li>
                </ul>
              </div>
              
              <div class="col-lg-3 col-md-6 col-xs-12">
                <ul style={{listStyleType:'none'}}>
                  <li style={{fontSize:'50px'}}>
                    <FaUsers/>
                  </li>
                  <li><span><b>Team Size:</b> <br/> 2 or 4 members</span></li>
                </ul>
              </div>
              <div class="col-lg-3 col-md-6 col-xs-12">
                <ul style={{listStyleType:'none'}}>
                  <li style={{fontSize:'50px'}}>
                    <FaMoneyBill/>
                  </li>
                  <li><span><b>Registration:</b><br/> Free</span></li>
                </ul>
              </div>
            </div>
          </div>
          </section>
        </Container>

        <Container>
          <h1 style={{textAlign:'center', paddingBottom:'40px', paddingBottom:'40px'}}>In association with</h1>
          <div style={{backgroundColor: "black"}}>
            <Card inverse>
              <CardImg
                alt="Card image cap"
                src={iccLogo}
                width="100%"
                style={{backgroundColor: "black"}}
              />
            
            </Card>
          </div>
        </Container>

        <PrevYearStat data={statData}/>
        
        {/* <Schedule /> */}

        <Domain />

        <div>
          <Container>
            <CodertineTimeline/>
          </Container>
        </div>
        <div className="section prizes">
          <Container style={{fontSize:'20px'}}>
            <Row className="justify-content-between">
              <Col md="6">
                <Row className="justify-content-between align-items-center">
                  <UncontrolledCarousel items={carouselItems} />
                </Row>
                {/* <br/><br/>
          
                        
                        
                          Off-Track Prizes<br/>
                          <ul>
                            <li>Rs 20,000 for best project built on Celo & Continuity Support. </li>
                            <li>Rs 20,000 for best project built on Tezos & Continuity grant opportunity up to $5k for outstanding projects.</li>
                            <li>Rs 10,000 and Rs 15,000 for the best hack built on Ethereum and Ethereum + Polygon network respectively.</li>
                            <li>Eligibility to apply for internship/full-time roles at Polygon and seed funding of upto $5k for winners ! </li>
                            <li>Rs 20,000 for best use of IPFS and/or Filecoin.</li>
                          </ul> 
                       
            
                <br/><br/> */}
              </Col>
              <Col md="6">
                <h1 className="profile-title text-left">Prizes</h1>
                <h5 className="text-on-back">02</h5>
                <p  className="profile-description text-left aboutCod" style={{fontSize:'20px'}}>
                 The prizes are as follows:<br/><br/>
                <ul>
                  <li>
                    <ul>
                      <h3 style={{fontWeight:"bold"}}>
                      1st place:
                      </h3>
                      <li>Prizes worth: 30K Cash + 20k Worth Vouchers</li>
                      {/* <li>Swags, Vouchers and Goodies</li>
                      <li>Full-time/Internship opportunities</li>
                      <li>Certificate of appreciation</li>
                      <li>Much more ...</li> */}
                    </ul>
                  </li>
                  <br/>
                  <li>
                    <ul>
                      <h3 style={{fontWeight:"bold"}}>
                      2nd place: 
                      </h3>
                      <li>Prizes worth: 20K Cash + 15k Worth Vouchers</li>
                      {/* <li>Swags and Goodies</li>
                      <li>Full-time/Internship opportunities</li>
                      <li>Certificate of appreciation</li>
                      <li>Much more ...</li> */}
                    </ul>
                  </li>
                  <br/>
                  <li>
                    <ul>
                      <h3 style={{fontWeight:"bold"}}>
                      3rd place:
                      </h3>
                      <li>Prizes worth: 10K Cash + 10K Vouchers</li>
                      {/* <li>Swags and Goodies</li>
                      <li>Full-time/Internship opportunities</li>
                      <li>Certificate of appreciation</li>
                      <li>Much more ...</li> */}
                    </ul>
                  </li>
                  <br/>
                  {/* <li>
                    <ul>
                      <h3 style={{fontWeight:"bold"}}>
                      4th to 10th place:
                      </h3>
                      <li>Swags and Goodies</li>
                      <li>Full-time/Internship opportunities</li>
                      <li>Certificate of appreciation</li>
                      <li>Much more ...</li>
                    </ul>
                  </li> */}
                  <br/>
                  <br/>
                  {/* <li>Winners stand a chance to win goodies from geek for geeks depending on their overal performance. 
This is subjective to the policies of geek for geeks.</li> */}
                </ul>
              </p>
                
              </Col>
            </Row>
          </Container>
        </div>
        <section className="section">
          <Container>
            <Row>
              <Col md="8">
                <Card className="card-plain">
                  <CardHeader>
                    <h1 className="profile-title text-left">Register fast 🔥 before we meet our Limit</h1>
                    <h5 className="text-on-back">03</h5>
                  </CardHeader>
                  <CardBody>
                        <Card className="card-coin card-plain">
                        <CardHeader>
                          <div ref={spaceship}></div>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col className="text-center" md="12">

                              <h4 style={{fontSize:"40px"}} className="text-uppercase">Register Fast ! </h4>
                              <span style={{fontSize:"25px"}}>Register yourself for Recursion here!</span>
                              <br/>
                              <Button
                                className="btn-simple"
                                color="info"
                                href="https://forms.gle/sjnKo8jr2P3Gk59Z6"
                                target="_blank"
                              >
                              
                                <i className="tim-icons icon-bulb-63" /> Register
                              
                            </Button>
                            
                              {/* <hr className="line-success" />
                              <span style={{fontSize:"25px",textAlign:"left"}}> 2) Then, fill the below google form with the same mail id you used while registering yourself on Hacker Blocks</span>
                              <br/>
                              <Button 
                                  className="btn-simple" 
                                  color="success" 
                                  href="https://forms.gle/B8fZsro9c2ia6kv6A"
                                  target="_blank"
                                >
                                  REGISTER
                              </Button>
                              <br/>
                              <hr className="line-success" />
                              <span style={{fontSize:"25px",textAlign:"left"}}> 3) You're done</span><br/>
                              <hr className="line-success" />

                              <span style={{fontSize:"25px",textAlign:"left"}}> 4) We will add you to the WhatsApp group after verification</span> */}
                            </Col>
                          </Row>
                          
                        </CardBody>
                        
                      </Card>
                  </CardBody>
                </Card>
              </Col>
              <Col className="ml-auto" md="4" >
                <div className="info info-horizontal" >
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-square-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title" style={{fontSize:"25px"}}>Find us at</h4>
                    <div className="button-container">
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="https://www.linkedin.com/company/rgit-codecell/"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin" />
                    </Button>
  
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="https://www.instagram.com/rgitcodecell"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                  </div>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-mobile" />
                  </div>
                  <div className="description">
                    <h4 className="info-title" style={{fontSize:"25px"}}>Give us a ring</h4>
                    <p style={{fontSize:"20px"}}>
                    Krishna - 9082087358 <br/> Anushka - 9987848258  <br/> Chirag-8450940529  <br /> Rohan Rathod - 8104660586
                      <br />
                      Mon - Fri, 8:00-22:00
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {/* <Container>
          <h3 style={{color: "white"}} className="text-center faq">•FAQ's•</h3>
        <div>
          <input type="checkbox" id="question1" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question1" className="question">What is Codertine? </label>
          <div className="answers">
            <p>
            Codertine is a 2.5-hour long coding challenge open to all coding enthusiasts to compete for a wide variety of opportunities and prizes .
            </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question2" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question2" className="question">
          What are the prizes up for grabs?

          </label>
          <div className="answers">
            <p>
            The prizes are as follows:
            1st place: Rs. 2500/- cash prize,
            90% off on any online course by coding blocks, Rs. 1200/- geeksforgeeks discount coupon, 30% off on any one Abner Security course / 40% off on Practical Big Bounty by Abner Security & certificate.
            2nd place: Rs. 1500/- cash prize & 
            85% off on any online course by coding blocks, Rs. 800/- geeksforgeeks discount coupon, 30% off on any one Abner Security course & certificate.
            3rd place: Rs. 1000/- cash prize &
            80% off on any online course by coding blocks, Rs. 500/- geeksforgeeks discount coupon, 30% off on any one Abner Security course and certificate.
            4th to 10th place: 20% off on any one Abner Security course & certificate.
            For all participants: Rs. 300/- off geeksforgeeks discount coupon & certificate.

            </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question4" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question4" className="question">
          What programming languages can we use?
          </label>
          <div className="answers">
            <p>
            All the latest programming languages are accepted (C/C++/Python/Java)
            </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question5" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question5" className="question">
          Can we team up with others?
          </label>
          <div className="answers">
            <p>
            No, it is a solo event hence only individuals, and not groups, can compete.
            </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question7" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question7" className="question">
          How do I register for the Codertine?
          </label>
          <div className="answers">
            <p>
            You have come to the right place! Just scroll up and click on the ‘Register now’ button and you will be guided to a form where you will be asked to fill certain details as instructed ALSO register on the Hackerblocks site: https://hack.codingblocks.com/.

            </p>
          </div>
          </div>
        <div>
          <input type="checkbox" id="question8" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question8" className="question">
            When and where should I pay?
          </label>
          <div className="answers">
          <p>
            The last date to register and pay is 11th November 2021. The payment link is present in the registration form itself. The registration fee is as follows:<br/>
          
            <ul>
                <li>RGIT CSI: Rs.30</li>
                <li> Non-RGIT CSI: Rs. 50 </li> 
            </ul>
          </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question9" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question9" className="question">
          Can I cancel my entry once paid?
          </label>
          <div className="answers">
          <p>
            No, we do not provide this option. Once paid, the registration is finalized.
          </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question10" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question10" className="question">
          Is this event open to all ages?
          </label>
          <div className="answers">
          <p>
          This event is limited to those 18 and up for participants. Please keep your college ID ready with you for verification.
          </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question11" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question11" className="question">
          Who can participate?  Are there any prerequisites or skills required?
          </label>
          <div className="answers">
          <p>
          Anyone is welcome to participate in this event. The event serves as a platform for all individuals, regardless of their experience with such events.
          </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question12" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question12" className="question">
          When and where will the Codertine be conducted?          </label>
          <div className="answers">
          <p>
          The competition is going to be conducted on 13th November 2021, 11AM onward. It will be held online on coding blocks. Please check the group for further notifications about it.
          </p>
          </div>
        </div>
          </Container> */}
          <Container className="sponsers" style={{backgroundColor:'#e8e8e8'}}>
          <div className="container">
            <div className="logo">
            <h3 style={{color: "black", fontWeight:'bold', fontSize:'30px'}} className="text-center" >•Our Sponsors•</h3><br/>
            {/* <div className="row d-flex justify-content-center">
              <h4 style={{fontWeight:'bold', fontSize:'20px', color: 'black'}}>Title Sponsor</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <a href=""><img src={defaultImg} className="img-fluid"  /></a>
              </div>
            </div> */}

            
            {/* <hr class="line_1" />

            <br/>
            <h3 style={{fontWeight:'bold', fontSize:'20px', paddingTop: "30px", color: 'black', textAlign:"center"}}>Title Sponsor</h3>

            <div className="row d-flex justify-content-center">
              <div className="d-flex justify-content-center">
              <Container >
                  <Row>
                      <Col lg="12" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://internetivo.com/" target="_blank"><img src={internetivo} className="img-fluid" /></a> 
                      </Col>
                  </Row>
              </Container>
              </div>
            </div>
             */}
             <hr class="line_1" />
            <br/>
            <div className="row d-flex justify-content-center">
              <h4 style={{fontWeight:'bold', fontSize:'20px', paddingTop: "30px", color: 'black'}}>Platform Partner</h4>
              <div className="col-sm-12 d-flex justify-content-center">
              <Container >
                  <Row>
                      <Col lg="6" md="6" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://devfolio.co" target="_blank"><img src={devfolio} className="img-fluid" /></a> 
                      </Col>
                      <Col lg="6" md="6" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://polygon.technology/" target="_blank"><img src={polygon} className="img-fluid" /></a> 
                      </Col>
                  </Row>
              </Container>
              </div>
            </div>
            
            <hr class="line_1" />
            <br/>
            <div className="row d-flex justify-content-center">
              <h4 style={{fontWeight:'bold', fontSize:'20px', paddingTop: "30px", color: 'black'}}>Title Sponsor</h4>
              <div className="col-sm-12 d-flex justify-content-center">
              <Container >
                  <Row>
                      <Col lg="6" md="6" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={veefly} style={{maxHeight:'200px',maxWidth:'200px'}} className="img-fluid" /></a> 
                      </Col>
                      <Col lg="6" md="6" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={promodome} style={{maxHeight:'200px',maxWidth:'200px'}} className="img-fluid" /></a> 
                      </Col>
                  </Row>
              </Container>
              </div>
            </div>
            <hr class="line_1" />

            <div className="row d-flex justify-content-center">
              <h4 style={{fontWeight:'bold', fontSize:'20px', paddingTop: "30px", color: 'black'}}>Partners</h4>
              <div className="col-sm-12 d-flex justify-content-center">
              <Container>
                  <Row>
                      {/* <Col lg="4" md="4" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://www.codingminutes.com/" target="_blank"><img src={codingMin} className="img-fluid" /></a> 
                      </Col> */}
                      <Col lg="4" md="4" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://filecoin.io " target="_blank"><img src={filecoin} className="img-fluid" /></a> 
                      </Col>
                      <Col lg="4" md="4" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://celo.org" target="_blank"><img src={celo} className="img-fluid" /></a> 
                      </Col>
                      <Col lg="4" md="4" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://tezos.com" target="_blank"><img src={tezos} className="img-fluid" /></a> 
                      </Col>
                    
                      <Col lg="4" md="4" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://gen.xyz/" target="_blank"><img src={xyz} className="img-fluid" /></a> 
                      </Col>
                      {/* <Col lg="4" md="4" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://artofproblemsolving.com/" target="_blank"><img src={aops} className="img-fluid" /></a> 
                      </Col> */}
                      
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://artofproblemsolving.com/" target="_blank"><img src={aops} className="img-fluid" /></a> 
                      </Col>
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="http://rosenfeldmedia.com" target="_blank"><img src={rosenfeld} className="img-fluid" /></a> 
                      </Col>
                       
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://replit.com" target="_blank"><img src={replit} className="img-fluid" /></a> 
                      </Col>
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://solana.com " target="_blank"><img src={solana} className="img-fluid" /></a> 
                      </Col>
                      
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={syb} className="img-fluid" /></a> 
                      </Col>
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={interview} className="img-fluid" /></a> 
                      </Col>
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={soe} className="img-fluid" /></a> 
                      </Col>
                      
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={man} className="img-fluid" /></a> 
                      </Col>
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={taskade} className="img-fluid" /></a> 
                      </Col>
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={w} className="img-fluid" /></a> 
                      </Col>
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={rogue} className="img-fluid" /></a> 
                      </Col>
                      
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={echo} className="img-fluid" /></a> 
                      </Col>
                      
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={hover} className="img-fluid" /></a> 
                      </Col>
                      
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={mentor} className="img-fluid" /></a> 
                      </Col>
                      
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={revup} className="img-fluid" /></a> 
                      </Col>
                      
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={axure} className="img-fluid" /></a> 
                      </Col>
                      
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={mindflix} className="img-fluid" /></a> 
                      </Col>
                      
                      <Col lg='4' md='4' style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img src={singh} className="img-fluid" /></a> 
                      </Col>
                  </Row>
              </Container>

              

              </div>
            </div>

            <div className="row d-flex justify-content-center">
              <div className="d-flex justify-content-center">
              <Container >
                  <Row >
                  </Row>
              </Container>
              </div>
            </div>
            <hr class="line_1" />
            <br/>
            <h4 style={{fontWeight:'bold', fontSize:'20px', paddingTop: "30px", color: 'black', textAlign:'center'}}>Food Partner</h4>
            <div className="row d-flex justify-content-center">
              <div className="d-flex justify-content-center">
              <Container>
                  <Row>
                      <Col style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="" target="_blank"><img style={{maxHeight:'200px',maxWidth:'200px'}} src={waffle} className="img-fluid" /></a> 
                      </Col>
                  </Row>
              </Container>

              </div>
            </div>{/* 
            <hr class="line_1" />
            <h4 style={{fontWeight:'bold', fontSize:'20px', paddingTop: "30px", color: 'black', textAlign:'center'}}>Interview Partner</h4>

            <div className="row d-flex justify-content-center">
              <div className="d-flex justify-content-center">
              <Container>
                  <Row>
                      <Col lg="12" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <a href="https://interviewbuddy.in/" target="_blank"><img src={interviewBuddy} className="img-fluid" /></a> 
                      </Col>
                  </Row>
              </Container>

              </div>
            </div> */}
            {/*
             <hr class="line_1" />
            <br/>

            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Technical Sponsor</h4>
              <div className="col-sm-12 d-flex justify-content-center">
              <a href="https://www.wolfram.com/language/#:~:text=Scalable%20for%20programs%20from%20tiny,first%20true%20computational%20communication%20language"> <img src="https://res.cloudinary.com/devdemo/image/upload/v1635158736/codecell%20website%20gallery%20images/sponsersLogo/wolfram_mflp4l.jpg" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div>
             <hr class="line_1" />
            <br/>
            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Education Sponseor</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <a href="https://www.endeavorcareers.com/"><img src="https://res.cloudinary.com/devdemo/image/upload/v1635158734/codecell%20website%20gallery%20images/sponsersLogo/endeavor_r8u2ng.png" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div>
             <hr class="line_1" />
            <br/>
            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Gaming Sponsor</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <img src="https://res.cloudinary.com/devdemo/image/upload/v1635158735/codecell%20website%20gallery%20images/sponsersLogo/PDC_Logo_18_jul-removebg-preview_rt5gxo.png" className="img-fluid" style={{width: "300px"}}/>
              </div>
            </div>
             <hr class="line_1" />
            <br/>
            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Counselling Sponsor</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <a href="https://learningedgeindia.com/"> <img src="https://res.cloudinary.com/devdemo/image/upload/v1635158734/codecell%20website%20gallery%20images/sponsersLogo/LearningEdge_xfrypb.jpg" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div>
             <hr class="line_1" />
            <br/>

            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Tshirt Partner</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <a href="https://www.dudeme.in/"><img src="https://res.cloudinary.com/devdemo/image/upload/v1635158735/codecell%20website%20gallery%20images/sponsersLogo/tshirt_n75ucs.png" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div>
             <hr class="line_1" />
            <br/>

            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Dessert Partner</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <img src="https://res.cloudinary.com/devdemo/image/upload/v1635158735/codecell%20website%20gallery%20images/sponsersLogo/MonkeyDough_z5zm6h.jpg" className="img-fluid" style={{width: "300px"}}/>
              </div>
            </div>
             <hr class="line_1" />
            <br/>

            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Community Partner</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <a href="https://ace.codeasylums.com/"><img src="https://res.cloudinary.com/devdemo/image/upload/v1635158733/codecell%20website%20gallery%20images/sponsersLogo/codeasylum_ffqlbu.jpg" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div>
             <hr class="line_1" />
            <br/>

            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Voucher Partner</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <a href="https://badelog.in/"><img src="https://res.cloudinary.com/devdemo/image/upload/v1635158733/codecell%20website%20gallery%20images/sponsersLogo/BadeLog.in_-_Logo_Black_orgtrs.png" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div> */}
            
          </div>
          </div> 
          </Container>
          <br/><br/>

          <div className="section rules" id="rules">
          <Container>
            <Row className="justify-content-between">
              <Col md="12">
                <h1 className="profile-title text-left">Rule & Regulations</h1>
                <h5 className="text-on-back">04</h5>
                <h4>
           Rules and regulations to be followed by participants:
           </h4>
           <br/>
           <h3>During registration:</h3>
           <ul>
             <li>Registrations beyond the registration date will not be entertained.</li>
             <li>Participants are encouraged to give valid information about themselves.</li>
             <li>Changes to the team after registration won’t be entertained, but some exceptions may be made (Contact the organizers)</li>

           </ul>
           <h3>After selection:</h3>
           <ul>
             <li>Selected teams need to confirm participation. Not doing so may lead to their spot being given to another team.</li>
             <li>Any sort of bribery to the staff will not be entertained and can also lead to disqualification.</li>
           </ul>
           <h3>During the event:</h3>
           <ul>
             {/* <li>The coding segment starts sharp at (timings) on 21/4/22 and ends at (timings) on 22/4/22.</li> */}
             <li>Requests to change the domain will not be entertained.</li>
             <li>Usage of expressions that are vulgar, obscene or derogatory in any manner is highly discouraged.</li>
             <li>Try to develop applications that do not deviate too much from the actual topic.</li>
             <li>Violation of copyrights, trademarks, or other such rights can lead to disqualification.</li>
             <li>Receiving assistance from an external party (those who aren’t part of the event) is prohibited.</li>
             <li>Teams are expected to stop coding when the allotted time is over.</li>
             <li>Every team should upload and host their project as a public git repository. This will be counted as a submission.</li>
             <li>The project should also be submitted on Devfolio before the deadline. </li>
             <li>Anyone involved in malpractice will be disqualified.</li>
             
           </ul>
           <h3>After the results are announced:</h3>
           <ul>
             <li>The decision made by the judges is considered final, no arguments will be entertained.</li>
             <li>In case of a tie, decisions will be made accordingly.</li>
             <li>Prizes are subject to change in case of extreme situations. Accordingly, members of CESS and CodeCell and the college staff or members cannot be held responsible.</li>
           </ul>
              </Col>
            </Row>
          </Container>
        </div>
        </section>
        
        <Footer />
      </div>
    </>
  );
}
