import React from "react";
import '../../Team.css'

import fletcher from '../../assets/img/team22/fletcher.jpeg'
import harsheet from '../../assets/img/team 23/harsheet.jpg'
import labeeb from '../../assets/img/team 23/labeeb.jpg'
import bhag from '../../assets/img/team 23/bhag.jpeg'
import tushar from '../../assets/img/team 23/tushar.jpg'
import aman from '../../assets/img/team 23/amaan.jpg'
import chirag from '../../assets/img/team 23/chirag.jpg'
import anusha from '../../assets/img/team22/anusha.jpg'
import aniket from '../../assets/img/team 23/aniket.jpg'
import Siddhi from '../../assets/img/team22/siddhi.jpg'
import yogita from '../../assets/img/team 23/yogita.jpg'
import adrash from '../../assets/img/team 23/adarsh.jpg'
import dharm from '../../assets/img/team 23/dharm.jpg'
import sanya from '../../assets/img/team 23/sanya.JPG'
import disha from '../../assets/img/team 23/disha.jpg'
import anushka from '../../assets/img/team 23/anushka.JPG'
import ishita from '../../assets/img/team 23/ishita.jpg'
import kishan from '../../assets/img/team 23/kishan.jpg'


import dilipSir from '../../assets/img/team22/dilipSir.jpeg'

import {
  Container,
  
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";


export default function team22_22() {
 

  return (
    <>
      <ExamplesNavbar />
      <Container style={{marginTop:80}}>
         
                    
        <h1>Team 22-23</h1>
        <div class="wrapper">
        <div>
        <h1 style={{marginTop: 70,textAlign: "center", color:'#ffffff'}}>• Meet Our Team •</h1>
        </div>
        {/* <!-- Our team starts --> */}
        <section class="section-team">
        <div class="container">
            {/* <!-- ------------------------------Row 1---------------------------------------> */}
            <div class="row" style={{justifyContent: 'center'}}>
            <div class="row" style={{justifyContent: 'center'}}>
                <div class="col-sm-6 col-lg-4 col-xl-3">
                    <div class="single-person">
                    <div class="person-image">
                        <img src={dilipSir} alt="" />
                    </div>
                    <div class="person-info">
                        <h4 class="full-name">Prof. Dilip Dalgade</h4>
                        <h5 class="speciality" style={{fontSize:"15px"}}>Convenor of RGIT CESS and CODECELL</h5>
                    </div>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={fletcher} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Fletcher Fernandes</h4>
                    <h5 class="speciality">Lead</h5>
                </div>
                </div>
            </div>

            
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={harsheet} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Harsheet Soni</h4>
                    <h5 class="speciality">Lead</h5>
                </div>
                </div>
            </div>

            

            
            </div>
            {/* <!-- ------------------------------Row 2---------------------------------------> */}

            <div class="row" style={{justifyContent: 'center'}}>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={labeeb} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Labeeb Ansari</h4>
                    <h5 class="speciality">Asst. Lead</h5>
                </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={bhag} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Bhagyasha Patil</h4>
                    <h5 class="speciality">Asst. Lead</h5>
                </div>
                </div>
            </div>

            

            </div>

            <div class="row" style={{justifyContent: 'center'}}>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={aniket} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Aniket Chauhan</h4>
                    <h5 class="speciality">Technical Head</h5>
                </div>
                </div>
            </div>


            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={Siddhi} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Siddhi Pevekar</h4>
                    <h5 class="speciality" style={{fontSize: 16}}> Technical Head</h5>
                </div>
                </div>
            </div>
            </div>
            <div class="row" style={{justifyContent: 'center'}}>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={yogita} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Yogita Chauhdari</h4>
                    <h5 class="speciality">Web  Head</h5>
                </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={adrash} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Adarsh pandey</h4>
                    <h5 class="speciality"> Web Head</h5>
                </div>
                </div>
            </div>
            </div>

            <div class="row" style={{justifyContent: 'center'}}>

            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={tushar} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Tushar Barkur</h4>
                    <h5 class="speciality">Operations Head</h5>
                </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={aman} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Amaan Khan</h4>
                    <h5 class="speciality" style={{fontSize: 16}}> Operations Head</h5>
                </div>
                </div>
            </div>


           
            </div>

            
            <div class="row" style={{justifyContent: 'center'}}>
            
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={anusha} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Anusha S.</h4>
                    <h5 class="speciality" style={{fontSize: 16}}> Logistics Head</h5>
                </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={chirag} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Chirag K.</h4>
                    <h5 class="speciality" style={{fontSize: 16}}> Logistics head</h5>
                </div>
                </div>
            </div>
            
            </div>

            
            
            
            
            <div class="row" style={{justifyContent: 'center'}}>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={dharm} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Dharamraj Rathod </h4>
                    <h5 class="speciality" style={{fontSize: 16}}>Digital Creative Head </h5>
                </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={sanya} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Sanya S.</h4>
                    <h5 class="speciality" style={{fontSize: 13}}> Digital Creative Head </h5>
                </div>
                </div>
            </div>
            
            </div>
            <div class="row" style={{justifyContent: 'center'}}>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={disha} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Disha Dhako</h4>
                    <h5 class="speciality">Publicity Head</h5>
                </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={anushka} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Anushka Matey</h4>
                    <h5 class="speciality" style={{fontSize: 16}}> Publicity Head</h5>
                </div>
                </div>
            </div>
            <div class="row" style={{justifyContent: 'center'}}>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={ishita} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Ishita Borkar</h4>
                    <h5 class="speciality">Marketing Head</h5>
                </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={kishan} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Krishna Yadav</h4>
                    <h5 class="speciality" style={{fontSize: 16}}> Marketing Head</h5>
                </div>
                </div>
            </div></div>
            
        </div>
        </div>
        </section>
  </div>


        
      </Container>
      <Footer/>
    </>
  );
}
