import React from "react";
import '../../Team.css';

import saad from '../../assets/img/recursion/saad.jpeg'
import aayush from '../../assets/img/recursion/aayush.jpeg'
import nitish from '../../assets/img/recursion/nitish.jpeg'


import {
  Container,
 
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";


export default function Founders() {

  return (
    <>
      <ExamplesNavbar />
      <Container style={{marginTop:80}}>
         
                    
                <h1>Founders</h1>
                <div class="wrapper">
    <div>
      <h1 style={{marginTop: 70,textAlign: "center", color:'#ffffff'}}>• Founders •</h1>
    </div>
    <section class="section-team">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-lg-4 col-xl-4">
            <div class="single-person">
              <div class="person-image">
                <img src={saad} alt="" />
              </div>
              <div class="person-info">
                <h4 class="full-name">Saad Ghojaria</h4>
                <p style={{color:'black', fontSize:'18px', paddingBottom:'160px'}}>RGIT CodeCell started with a mindset of expanding knowledge. I believe one can be successful by gaining knowledge as much as they can, but it won't make justice until it has been passed on. RGIT Codecell was started with the same vision and I think this is what success will look like. It's great to see the chain of passing on knowledge is still going on after us. Codecell roots were started with too much effort of people who were working behind the scenes too. My wishes will be always there with the current and future teams and RGIT Codecell still has our hearts we are always there whenever CodeCell will need it. <br/><br/> - <span style={{fontWeight:'bold'}}>Saad Ghojaria </span> <br/>( Northeastern University MSIS Program)</p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 col-xl-4">
            <div class="single-person">
              <div class="person-image">
                <img src={aayush} alt="" />
              </div>
              <div class="person-info">
                <h4 class="full-name">Aayush Singh</h4>
                <p style={{color:'black', fontSize:'18px', paddingBottom:'350px'}}>RGIT-Codecell was established as a tool to enhance collaboration between like-minded students. The motivation was to create a platform to share and learn with Coders irrespective of their class, division or year. It started with Coding Pandas and transformed into a community, which collectively host the first-ever Hackathon. Extremely proud of how we have developed and wish it continues! <br/><br/>  -- <span style={{fontWeight:'bold'}}>Aayush Singh</span> <br/> ( North Carolina State University - CS Program)</p>
              </div>
            </div>
          </div>

          <div class="col-sm-6 col-lg-4 col-xl-4">
            <div class="single-person">
              <div class="person-image">
                <img src={nitish} alt=""/>
              </div>
              <div class="person-info">
                <h4 class="full-name">Nitish Talekar</h4>
                <p style={{color:'black', fontSize:'18px'}}>As someone who has seen CodeCell grow from an idea to a plan and eventually the community it is now, I am extremely grateful for all the factors that brought us here.
                    We started CodeCell in an attempt to rally all like-minded coding enthusiasts so that they could be part of something greater than themselves.

                    This community is a stepping stone for everyone who wants to explore technology outside the curriculum.

                    Our main objectives were to create an environment of interaction between people with a love for coding and give everyone the chance to participate in coding challenges, project drives and other events that help them experience new and coming technologies.

                    I am truly proud to see the level of impact this community has had in such a short span of time.


                    <br/>-- <span style={{fontWeight:'bold'}}>Nitish Talekar </span> <br/> ( North Carolina State University - CS Program)</p>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </section>
  </div>


        
      </Container>
      <Footer/>
    </>
  );
}
