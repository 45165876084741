const data =  [
    {
        "id": "001",
        "label": "Participate",
        "number": 632,
        "duration": 6,
        "logo": "<FaUserAlt />"
    },
    {
        "id": "002",
        "label": "Teams",
        "number": 112,
        "duration": 6,
        "logo": "<FaUserFriends/>"
    },
    {
        "id": "003",
        "label": "Colleges",
        "number": 15,
        "duration": 6,
        "logo": "<FaUniversity/>"
    },
    {
        "id": "004",
        "label": "Sponsors",
        "number": 8,
        "duration": 6,
        "logo": "<FaHandsHelping />"
    }
];

export default data;