// ----------------Timeline----------------------
import React, { useEffect, useRef, useState } from "react";

import TimelineObserver from "react-timeline-animation";
import { fireConfetti } from "./confetti";


const Timeline = ({ setObserver, callback }) => {
    const [message1, setMessage1] = useState("");
    const [message1Date, setMessage1Date] = useState("");
    const [message2, setMessage2] = useState("");
    const [message2Date, setMessage2Date] = useState("");
    // const [message5, setMessage5] = useState("");
    // const [message5Date, setMessage5Date] = useState("");
    const [message6, setMessage6] = useState("");
    const [message6Date, setMessage6Date] = useState("");
    const [message3, setMessage3] = useState("");
    const [message3Date, setMessage3Date] = useState("");
    const [message4, setMessage4] = useState("");
    const [message4Date, setMessage4Date] = useState("");
    
  
    const timeline1 = useRef(null);
    const timeline2 = useRef(null);
    const timeline5 = useRef(null);
    const timeline6 = useRef(null);
    const timeline3 = useRef(null);
    const timeline4 = useRef(null);
    const circle1 = useRef(null);
    const circle2 = useRef(null);
    const circle5 = useRef(null);
    const circle6 = useRef(null);
    const circle3 = useRef(null);
    const circle4 = useRef(null);
  
    const someCallback = () => {
      setMessage1("Registrations Opens");

      setMessage1Date("01/03/2024");
      callback();
    };
  
    const someCallback2 = () => {
      setMessage2("Last date to Register");
      setMessage2Date("10/03/2024");
    };

    const someCallback6 = () => {
      setMessage6("Shortlisted Teams Announced");
      setMessage6Date("11/03/2024");
    };
  
    const someCallback3 = () => {
      setMessage3("Recursion 5.0");
      setMessage3Date("13/03/2024");
    };
    const someCallback4 = () => {
      setMessage4("Result");
      setMessage4Date("14/03/2024");
      fireConfetti();
    };
  
    useEffect(() => {
      setObserver(timeline1.current);
      setObserver(timeline2.current);
      setObserver(timeline5.current);
      setObserver(timeline6.current);
      setObserver(timeline3.current);
      setObserver(timeline4.current);
      setObserver(circle1.current, someCallback);
      setObserver(circle2.current, someCallback2);
      // setObserver(circle5.current, someCallback5);
      setObserver(circle6.current, someCallback6);
      setObserver(circle3.current, someCallback3);
      setObserver(circle4.current, someCallback4);
    }, []);
  
    return (
      <div className="wrapper-timeline">
        <div id="timeline1" ref={timeline1} className="timeline" />
        <div className="circleWrapper">
          <div id="circle1" ref={circle1} className="circle">
            1
          </div>
          <div className="message">{message1}</div>
          <div className="messageDate">{message1Date}</div>
        </div>
        <div id="timeline2" ref={timeline2} className="timeline" />
        <div className="circleWrapper">
          <div id="circle2" ref={circle2} className="circle">
            2
          </div>
          <div className="message">{message2}</div>
          <div className="messageDate">{message2Date}</div>
        </div>
        <div id="timeline5" ref={timeline5} className="timeline" />
        {/* <div className="circleWrapper">
          <div id="circle5" ref={circle5} className="circle">
            5
          </div>
          <div className="message">{message5}</div>
          <div className="messageDate">{message5Date}</div>
        </div> */}
        <div id="timeline6" ref={timeline6} className="timeline" />
        <div className="circleWrapper">
          <div id="circle6" ref={circle6} className="circle">
            6
          </div>
          <div className="message">{message6}</div>
          <div className="messageDate">{message6Date}</div>
        </div>
        <div id="timeline3" ref={timeline3} className="timeline" />
        <div className="circleWrapper">
          <div id="circle3" ref={circle3} className="circle">
            3
          </div>
          <div className="message">{message3}</div>
          <div className="messageDate">{message3Date}</div>
        </div>
        <div id="timeline4" ref={timeline4} className="timeline" />
        <div className="circleWrapper">
          <div id="circle4" ref={circle4} className="circle">
            4
          </div>
          <div className="message">{message4}</div>
          <div className="messageDate">{message4Date}</div>
        </div>
      </div>
    );
  };
  
  export default function CodertineTimeline() {
    const [message, setMessage] = useState("");
  
    const onCallback = () => {
      console.log("awesome");
    };
  
    return (
      <div className="App aboutEvent">
        <br/>
        <br/>
        <h1 style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>•Event Flow•</h1>
        <br/>
        <TimelineObserver
          initialColor="#e5e5e5"
          fillColor="#08ffd6"
          handleObserve={(setObserver) => (
            <Timeline
              callback={onCallback}
              className="timeline"
              setObserver={setObserver}
            />
          )}
        />
        <div className="stub2">{message}</div>
      </div>
    );
  }
  
  
  //----------------Timeline End-------------------