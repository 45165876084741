import React from 'react'
import AIML from "../../assets/img/recursion/Domain/aiAndml.jpg";
import Industrial from "../../assets/img/recursion/Domain/industrial.jpg";
import IOT from "../../assets/img/recursion/Domain/iotAndrobotics.jpg";
import WEBAPP from "../../assets/img/recursion/Domain/webAndapp.jpg";
import { Card, Row, Col } from 'react-bootstrap';
const Domain = () => {

    const data = [
        {
            label: "AI/ML",
            path: AIML
        },
        {
            label: "WEB and APP Development",
            path: WEBAPP
        },
        {
            label: "Blockchain",
            path: IOT
        },
    ];
    

  return (
    <>

        
      <div class="card text-white stat-box domain-box">
          <h2 style={{paddingBottom:'30px', fontWeight:"bold",paddingTop:"150px"}}>Domains to choose</h2>
          <div>
            <Row xs={1} md={2} className="g-4">
            {data.map((currEle) => (
                <Col> 
                <Card className='domain-box'>
                    <Card.Img variant="top" src={currEle.path} />
                    <Card.Body style={{backgroundImage:" linear-gradient( 90.0deg, rgb(37, 49, 56), rgb(0, 242, 195) 92.1% )"}}>
                    <Card.Title style={{fontSize:"20px", fontFamily: "Poppins"}}>{currEle.label}</Card.Title>
                    {/* <Card.Text>
                        This is a longer card with supporting text below as a natural
                        lead-in to additional content. This content is a little bit longer.
                    </Card.Text> */}
                    </Card.Body>
                </Card>
                </Col>
            ))}
            </Row>

          </div>
      </div>
    </>
  )
}

export default Domain
