import React from "react";
import PhotoGallery from 'react-photo-gallery';

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";


export default function Gallery() {
 
  const photos = [
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819700/codecell%20website%20gallery%20images/Hackathon-3-min_cyx5st.jpg", 
      width:4, 
      height: 3
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819700/codecell%20website%20gallery%20images/Hackathon-min_kgicko.jpg", 
      width:4, 
      height: 6
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819700/codecell%20website%20gallery%20images/Hackathon-2-min_n5w6bo.jpg", 
      width:4, 
      height: 4
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819700/codecell%20website%20gallery%20images/Hackathon-4-min_gatc4g.jpg", 
      width:5, 
      height: 5
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819700/codecell%20website%20gallery%20images/Hackathon-10-min_dw4xkx.jpg", 
      width:4, 
      height: 3
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819700/codecell%20website%20gallery%20images/Hackathon-5-min_pssvrx.jpg", 
      width:8, 
      height: 5
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819700/codecell%20website%20gallery%20images/Hackathon-9-min_fmn2mf.jpg", 
      width:4, 
      height: 4
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819700/codecell%20website%20gallery%20images/Hackathon-6-min_c4s6we.jpg", 
      width:5, 
      height: 5
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819690/codecell%20website%20gallery%20images/Python_Workshop-4-min_wtcme9.jpg", 
      width:8, 
      height: 8
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819691/codecell%20website%20gallery%20images/Python_Workshop-min_cbfkdo.jpg", 
      width:4, 
      height: 4
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819690/codecell%20website%20gallery%20images/Python_Workshop-3-min_porlno.jpg", 
      width:5, 
      height: 6
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819690/codecell%20website%20gallery%20images/Python_Workshop-4-min_wtcme9.jpg", 
      width:4, 
      height: 3
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819690/codecell%20website%20gallery%20images/Python_Workshop-2-min_hltxlo.jpg", 
      width:12, 
      height: 8
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819690/codecell%20website%20gallery%20images/Python_Workshop-5-min_xfb2pk.jpg", 
      width:6, 
      height: 6
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819690/codecell%20website%20gallery%20images/Python_Workshop-6-min_ohs5l8.jpg", 
      width:5, 
      height: 6
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819690/codecell%20website%20gallery%20images/Python_Workshop-7-min_skcbgq.jpg", 
      width:8, 
      height: 6
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819680/codecell%20website%20gallery%20images/CodeCell_inauguration-min_x8chkk.jpg", 
      width:8, 
      height: 6
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819680/codecell%20website%20gallery%20images/CodeCell_inauguration-3-min_c0xkdz.jpg", 
      width:5, 
      height: 6
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819680/codecell%20website%20gallery%20images/CodeCell_inauguration-7-min_q5tzz1.jpg", 
      width:6, 
      height: 6
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819680/codecell%20website%20gallery%20images/CodeCell_inauguration-4-min_htdr6h.jpg", 
      width:5, 
      height: 6
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819680/codecell%20website%20gallery%20images/CodeCell_inauguration-2-min_ziar24.jpg", 
      width:5, 
      height: 5
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819680/codecell%20website%20gallery%20images/CodeCell_inauguration-6-min_bw3ila.jpg", 
      width:5, 
      height: 5
    },
    {
      src:"https://res.cloudinary.com/devdemo/image/upload/v1631819680/codecell%20website%20gallery%20images/CodeCell_inauguration-5-min_etgbx1.jpg", 
      width:5, 
      height: 5
    },
  ]

  return (
    <>
      <ExamplesNavbar />
      <div style={{marginTop:80}}></div>
      <PhotoGallery photos={photos} direction={"column"} />;
      <Footer/>
    </>
  );
}
