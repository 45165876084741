import React from "react";
import '../../Team.css'

import krishna from '../../assets/img/team24/Krishna Yadav (Lead).jpg'
import dharm from '../../assets/img/team24/Dharmaraj Rathod (assistant lead).jpg'
import aaditya from '../../assets/img/team24/aaditya.jpg'
import atharva from '../../assets/img/team24/Atharva Patil Technical lead 1.jpg'
import Shantanu from '../../assets/img/team24/Shantanu Gondane (technical head).jpg'
import Tushhar from '../../assets/img/team24/Tushhar Barkur 1 (Operations Head).jpg'
import priyanshu from '../../assets/img/team24/priyanshu.jpg'
import amey from '../../assets/img/team24/Amey(Logistics).jpg'
import pranav from '../../assets/img/team24/Pranav Iskande 1 Logistics Assistant.jpg'
import riya from '../../assets/img/team24/Riya Pradhan 1.jpg'
import kartik from '../../assets/img/team24/Kartik Mistry 1 (Publicity Head).jpg'
import shweta from '../../assets/img/team24/Shweta 1 marketing head.jpg'
import nandini from '../../assets/img/team24/nandini.png'
import garima from '../../assets/img/team24/Garima 1 (Web).jpg'
import tejas from '../../assets/img/team24/Tejas Pawar 2(Web Head).JPG.JPG'
import aditi from '../../assets/img/team24/Aditi Tatkare DC head 1.jpg'
import deepak from '../../assets/img/team24/Deepak Kumavat(DC head).jpg'


import dilipSir from '../../assets/img/team22/dilipSir.jpeg'

import {
  Container,
  
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";


export default function team22_22() {
 

  return (
    <>
      <ExamplesNavbar />
      <Container style={{marginTop:80}}>
         
                    
        <h1>Team 22-23</h1>
        <div class="wrapper">
        <div>
        <h1 style={{marginTop: 70,textAlign: "center", color:'#ffffff'}}>• Meet Our Team •</h1>
        </div>
        {/* <!-- Our team starts --> */}
        <section class="section-team">
        <div class="container">
            {/* <!-- ------------------------------Row 1---------------------------------------> */}
            <div class="row" style={{justifyContent: 'center'}}>
            <div class="row" style={{justifyContent: 'center'}}>
                <div class="col-sm-6 col-lg-4 col-xl-3">
                    <div class="single-person">
                    <div class="person-image">
                        <img src={dilipSir} className="image2" alt="" />
                    </div>
                    <div class="person-info">
                        <h4 class="full-name">Prof. Dilip Dalgade</h4>
                        <h5 class="speciality" style={{fontSize:"15px"}}>Convenor of RGIT CESS and CODECELL</h5>
                    </div>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={krishna} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Krishna Yadav</h4>
                    <h5 class="speciality">Lead</h5>
                </div>
                </div>
            </div>

            
            {/* <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={krishna} alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Harsheet Soni</h4>
                    <h5 class="speciality">Lead</h5>
                </div>
                </div>
            </div> */}

            

            
            </div>
            {/* <!-- ------------------------------Row 2---------------------------------------> */}

            <div class="row" style={{justifyContent: 'center'}}>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={dharm} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Dharamraj Rathod</h4>
                    <h5 class="speciality">Asst. Lead</h5>
                </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={aaditya} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Aaditya Shinde</h4>
                    <h5 class="speciality">Asst. Lead</h5>
                </div>
                </div>
            </div>

            

            </div>

            <div class="row" style={{justifyContent: 'center'}}>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={atharva} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Atharva Patil</h4>
                    <h5 class="speciality">Technical Head</h5>
                </div>
                </div>
            </div>


            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={Shantanu} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Shantanu Gondane</h4>
                    <h5 class="speciality" style={{fontSize: 16}}> Technical Head</h5>
                </div>
                </div>
            </div>
            </div>
            <div class="row" style={{justifyContent: 'center'}}>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={Tushhar} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Tushhar Barkur</h4>
                    <h5 class="speciality">Operation Head</h5>
                </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={priyanshu} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Priyanshu Naik</h4>
                    <h5 class="speciality"> Operations Head</h5>
                </div>
                </div>
            </div>
            </div>

            <div class="row" style={{justifyContent: 'center'}}>

            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={amey} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Amey Godbole</h4>
                    <h5 class="speciality">Logistics Head</h5>
                </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={pranav} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Pranav Iskande</h4>
                    <h5 class="speciality" style={{fontSize: 16}}>Asst. Logistics Head</h5>
                </div>
                </div>
            </div>


           
            </div>

            
            <div class="row" style={{justifyContent: 'center'}}>
            
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={riya} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Riya Pradhan</h4>
                    <h5 class="speciality" style={{fontSize: 16}}> Publicity Head</h5>
                </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={kartik} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Kartik Mistry</h4>
                    <h5 class="speciality" style={{fontSize: 16}}> Publicity head</h5>
                </div>
                </div>
            </div>
            
            </div>

            
            
            
            
            <div class="row" style={{justifyContent: 'center'}}>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={shweta} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Shweta Yadav </h4>
                    <h5 class="speciality" style={{fontSize: 16}}>Marketing Head </h5>
                </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={nandini} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Nandini Yadav</h4>
                    <h5 class="speciality" style={{fontSize: 13}}> Marketing Head </h5>
                </div>
                </div>
            </div>
            
            </div>
            <div class="row" style={{justifyContent: 'center'}}>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={garima} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Garima Dwivedi</h4>
                    <h5 class="speciality">Web Head</h5>
                </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={tejas} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Tejas Pawar</h4>
                    <h5 class="speciality" style={{fontSize: 16}}>Web Head</h5>
                </div>
                </div>
            </div>
            <div class="row" style={{justifyContent: 'center'}}>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={aditi} className="image2" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Aditi Tatkare</h4>
                    <h5 class="speciality">Digital Creative Head</h5>
                </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="single-person">
                <div class="person-image">
                    <img src={deepak} className="image" alt="" />
                </div>
                <div class="person-info">
                    <h4 class="full-name">Deepak Kumavat</h4>
                    <h5 class="speciality" style={{fontSize: 16}}> Digital Creative Head</h5>
                </div>
                </div>
            </div></div>
            
        </div>
        </div>
        </section>
  </div>


        
      </Container>
      <Footer/>
    </>
  );
}
