import React, {useEffect, useRef,  useState} from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Lottie from "lottie-web";
import classnames from "classnames";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import codecell from "../../assets/img/codertine/CodeCellLogo.png";
import cess from "../../assets/img/codertine/Cess.png";
import TimelineObserver from "react-timeline-animation";
import CodertineTimeline from "./CodertineTimeline";
import codingBlock from "../../assets/img/codertine/codingBlock.png"
import abner from "../../assets/img/codertine/abnerSec.png"
import codertineLogo from "../../assets/img/codertine/Codertine.png"
import gfg from "../../assets/img/codertine/gfg.png"
import pdf from "./brochure.pdf"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";

const carouselItems = [
  {
    src: require("assets/img/codertine/cash.jpg").default,
    altText: "Cash Prize",
    caption: "Win Cash Prizes",
  },
  {
    src: require("assets/img/codertine/giftVoc2.jpg").default,
    altText: "Gift Vouchers",
    caption: "Win exciting gift vouchers",
  },
  {
    src: require("assets/img/codertine/certf.png").default,
    altText: "Certficate",
    caption: "Earn Certificates",
  },
];


export default function Codertine() {
  const [tabs, setTabs] = React.useState(1);
  let ps = null;
  const spaceship = useRef(null)
  useEffect(()=>{
    Lottie.loadAnimation({
      container: spaceship.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../Lottie/spaceship.json')
    })
  }, [])

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    
  },[]);

  const astronaut = useRef(null)


  useEffect(()=>{
    Lottie.loadAnimation({
      container: astronaut.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require('../../Lottie/astronaut.json')
    })
  }, [])
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <Container className="headTop">
        <Row>
              <Col lg='12' md='12'>
                <div className="page-header">
                
                  <div className="content-center" >

                    <Row className="row-grid justify-content-between align-items-center text-left">
                      <Col lg="6" md="6">

                        {/* <h1 className="text-white" className="codertine-head">
                          CODER<span className="text-success">TINE</span> <br /><br/>
                        </h1> */}
                        <div style={{paddingTop:"5px"}}><img src={codertineLogo} alt="codertineLogo"  className="codertineLogo-logo"/> </div>
                        <p className="text-white mb-3 header-quote">
                        Codertine 2.0 is here again to bring out the coding spirit in you 🔥
                        </p>
                        <div className="btn-wrapper mb-3">
                          <Button
                            className="btn-link"
                            color="success"
                            size="sm"
                            style={{scrollBehavior:"smooth"}}
                          >
                          <Link
                            activeclassName="active"
                            to="about"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                          >
                          <br/>
                          <p className="category text-success d-inline start">
                            Let's Explore
                          </p>
                            <i className="tim-icons icon-minimal-right" />
                          </Link>
                          </Button>
                        </div>
                        
                      
                    </Col>

                    <Col lg="6" md="6">
                      <div ref={astronaut }>

                      </div>
                    </Col> 
                    
                  </Row>
                  
                </div>
              

              </div>
            </Col>
            </Row>
        </Container>
        <div className="page-header">
          
          <Container className="align-items-center" id="about">
            <Row>
              <Col lg="6" md="6">
                <h1 className="profile-title text-left">About</h1>
                <h5 className="text-on-back">01</h5>
                <p className="profile-description aboutCod" >
                RGIT Codecell along with RGIT CESS and RGIT CSI are conducting an online inter-college coding competition - CODERTINE 2.0 on Hacker Blocks, where students will be provided with problem statements that will revolve around the concepts of Competitive Programming. The event will be conducted on Hacker Rank coding platform of Coding Blocks for a duration of 2.5hrs. </p>
                
              </Col>
              <Col lg="6" md="6">
                <img src={codecell} alt="DSA"  className="codecell-logo"/> 
                <h1 style={{textAlign:"center"}}>&</h1><br/>
                <img src={cess} alt="DSA"  className="codecell-logo"/>           
              </Col>
              
            </Row>
           
            
          </Container>
        </div>
        <div>
          <Container>
            <CodertineTimeline/>
          </Container>
        </div>
        <div className="section prizes">
          <Container>
            <Row className="justify-content-between">
              <Col md="6">
                <Row className="justify-content-between align-items-center">
                  <UncontrolledCarousel items={carouselItems} />
                </Row>
                <br/><br/>
                <Row>
                        
                        <Col md="12" style={{fontSize:"22px"}} > 
                          There will be 5 problems<br/>
                          <ul>
                            <li>2 Easy Difficulty level</li>
                            <li>2 Medium Dificulty level</li>
                            <li>1 Hard Difficulty level</li>
                            <li>Scoring will be based on </li>
                            <li>Difficulty level of problem solved</li>
                            <li>Overall time taken to solve problem</li>
                            <li>Number of problems solved </li>
                            <li>Plagiarism checker</li>
                          </ul> 
                        </Col>
                      
                </Row>
                <br/><br/>
              </Col>
              <Col md="6">
                <h1 className="profile-title text-left">Prizes</h1>
                <h5 className="text-on-back">02</h5>
                <p className="profile-description text-left aboutCod">
                The prizes are as follows:<br/><br/>
                <ul>
                  <li>
                    <ul>
                      <h3 style={{fontWeight:"bold"}}>
                      1st place: 
                      </h3>
                      <li>Rs. 2500/- cash prize</li>
                      <li>90% off on any online course by coding blocks</li>
                      <li>Rs. 1200/- geeksforgeeks discount coupon</li>
                      <li>30% off on any one AbnerSecurity course or 40% off on Practical Bug Bounty, either one but not both by Abner Security </li>
                      <li>Certificate of appreciation</li>
                    </ul>
                  </li>
                  <br/>
                  <li>
                    <ul>
                      <h3 style={{fontWeight:"bold"}}>
                      2nd place: 
                      </h3>
                      <li>Rs 1500/- cash prize </li>
                      <li>85% off on any online course by coding blocks</li>
                      <li>Rs 800/- geeksforgeeks discount coupon</li>
                      <li>30% off on any one Abner Security course</li>
                      <li>Certificate of appreciation</li>
                    </ul>
                  </li>
                  <br/>
                  <li>
                    <ul>
                      <h3 style={{fontWeight:"bold"}}>
                      3rd place:
                      </h3>
                      <li>Rs. 1000/- cash prize </li>
                      <li>80% off on any online course by coding blocks</li>
                      <li>Rs. 500/- geeksforgeeks discount coupon</li>
                      <li>30% off on any one Abner Security course</li>
                      <li>Certificate of appreciation</li>
                    </ul>
                  </li>
                  <br/>
                  <li>
                    <ul>
                      <h3 style={{fontWeight:"bold"}}>
                      4th to 10th place:
                      </h3>
                      <li>20% off on any one Abner Security course </li>
                      <li>Certificate of appreciation</li>
                    </ul>
                  </li>
                  <br/>
                  <li>
                    <ul>
                      <h3 style={{fontWeight:"bold"}}>
                      For all participants:
                      </h3>
                      <li>Rs. 300/- off geeksforgeeks discount coupon s</li>
                      <li>Certificate of participation</li>
                    </ul>
                  </li>
                  <br/>
                  {/* <li>Winners stand a chance to win goodies from geek for geeks depending on their overal performance. 
This is subjective to the policies of geek for geeks.</li> */}
                </ul>
              </p>
                <div className="btn-wrapper pt-3">
                  <Button
                    className="btn-simple"
                    color="primary"
                    href={pdf}
                    target="_blank"
                  >
                    
                    <i className="tim-icons icon-book-bookmark" /> Brochure
                  </Button>
                  <Button
                    className="btn-simple"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                  <Link
                    activeclassName="active"
                    to="rules"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <i className="tim-icons icon-bulb-63" /> Rules !
                  </Link>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="section">
          <Container>
            <Row>
              <Col md="8">
                <Card className="card-plain">
                  <CardHeader>
                    <h1 className="profile-title text-left">Register fast 🔥 before we meet our Limit</h1>
                    <h5 className="text-on-back">03</h5>
                  </CardHeader>
                  <CardBody>
                        <Card className="card-coin card-plain">
                        <CardHeader>
                          <div ref={spaceship}></div>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col className="text-center" md="12">

                              <h4 style={{fontSize:"40px"}} className="text-uppercase">STEPS</h4>
                              <span style={{fontSize:"25px"}}>1) Register yourself on the Hacker Blocks platform</span>
                              <br/>
                              <Button
                                className="btn-simple"
                                color="info"
                                href="https://hack.codingblocks.com/"
                                target="_blank" 
                              >
                              
                                <i className="tim-icons icon-bulb-63" /> Register
                              
                            </Button>
                              <hr className="line-success" />
                              <span style={{fontSize:"25px",textAlign:"left"}}> 2) Then, fill the below google form with the same mail id you used while registering yourself on Hacker Blocks</span>
                              <br/>
                              <Button 
                                  className="btn-simple" 
                                  color="success" 
                                  href="https://forms.gle/B8fZsro9c2ia6kv6A"
                                  target="_blank"
                                >
                                  REGISTER
                              </Button>
                              <br/>
                              <hr className="line-success" />
                              <span style={{fontSize:"25px",textAlign:"left"}}> 3) You're done</span><br/>
                              <hr className="line-success" />

                              <span style={{fontSize:"25px",textAlign:"left"}}> 4) We will add you to the WhatsApp group after verification</span>
                            </Col>
                          </Row>
                          
                        </CardBody>
                        
                      </Card>
                  </CardBody>
                </Card>
              </Col>
              <Col className="ml-auto" md="4" >
                <div className="info info-horizontal" >
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-square-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title" style={{fontSize:"25px"}}>Find us at</h4>
                    <div className="button-container">
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="https://www.linkedin.com/company/rgit-codecell/"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin" />
                    </Button>
  
                    <Button
                      className="btn-icon btn-simple btn-round btn-neutral"
                      color="default"
                      href="https://www.instagram.com/rgitcodecell"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                  </div>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-mobile" />
                  </div>
                  <div className="description">
                    <h4 className="info-title" style={{fontSize:"25px"}}>Give us a ring</h4>
                    <p style={{fontSize:"20px"}}>
                    Harsheet - 9930154685<br/> Aniket - 8369526162<br/> Bhagyasha - 8408095999<br />
                      <br />
                      Mon - Fri, 8:00-22:00
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
          <h3 style={{color: "white"}} className="text-center faq">•FAQ's•</h3>
        <div>
          <input type="checkbox" id="question1" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question1" className="question">What is Codertine? </label>
          <div className="answers">
            <p>
            Codertine is a 2.5-hour long coding challenge open to all coding enthusiasts to compete for a wide variety of opportunities and prizes .
            </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question2" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question2" className="question">
          What are the prizes up for grabs?

          </label>
          <div className="answers">
            <p>
            The prizes are as follows:
            1st place: Rs. 2500/- cash prize,
            90% off on any online course by coding blocks, Rs. 1200/- geeksforgeeks discount coupon, 30% off on any one Abner Security course / 40% off on Practical Big Bounty by Abner Security & certificate.
            2nd place: Rs. 1500/- cash prize & 
            85% off on any online course by coding blocks, Rs. 800/- geeksforgeeks discount coupon, 30% off on any one Abner Security course & certificate.
            3rd place: Rs. 1000/- cash prize &
            80% off on any online course by coding blocks, Rs. 500/- geeksforgeeks discount coupon, 30% off on any one Abner Security course and certificate.
            4th to 10th place: 20% off on any one Abner Security course & certificate.
            For all participants: Rs. 300/- off geeksforgeeks discount coupon & certificate.

            </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question4" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question4" className="question">
          What programming languages can we use?
          </label>
          <div className="answers">
            <p>
            All the latest programming languages are accepted (C/C++/Python/Java)
            </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question5" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question5" className="question">
          Can we team up with others?
          </label>
          <div className="answers">
            <p>
            No, it is a solo event hence only individuals, and not groups, can compete.
            </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question7" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question7" className="question">
          How do I register for the Codertine?
          </label>
          <div className="answers">
            <p>
            You have come to the right place! Just scroll up and click on the ‘Register now’ button and you will be guided to a form where you will be asked to fill certain details as instructed ALSO register on the Hackerblocks site: https://hack.codingblocks.com/.

            </p>
          </div>
          </div>
        <div>
          <input type="checkbox" id="question8" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question8" className="question">
            When and where should I pay?
          </label>
          <div className="answers">
          <p>
            The last date to register and pay is 11th November 2021. The payment link is present in the registration form itself. The registration fee is as follows:<br/>
          
            <ul>
                <li>RGIT CSI: Rs.30</li>
                <li> Non-RGIT CSI: Rs. 50 </li> 
            </ul>
          </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question9" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question9" className="question">
          Can I cancel my entry once paid?
          </label>
          <div className="answers">
          <p>
            No, we do not provide this option. Once paid, the registration is finalized.
          </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question10" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question10" className="question">
          Is this event open to all ages?
          </label>
          <div className="answers">
          <p>
          This event is limited to those 18 and up for participants. Please keep your college ID ready with you for verification.
          </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question11" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question11" className="question">
          Who can participate?  Are there any prerequisites or skills required?
          </label>
          <div className="answers">
          <p>
          Anyone is welcome to participate in this event. The event serves as a platform for all individuals, regardless of their experience with such events.
          </p>
          </div>
        </div>
        <div>
          <input type="checkbox" id="question12" name="q" className="questions" />
          <div className="plus">+</div>
          <label for="question12" className="question">
          When and where will the Codertine be conducted?          </label>
          <div className="answers">
          <p>
          The competition is going to be conducted on 13th November 2021, 11AM onward. It will be held online on coding blocks. Please check the group for further notifications about it.
          </p>
          </div>
        </div>
          </Container>
          <Container className="sponsers">
          <div className="container">
            <div className="logo">
            <h3 style={{color: "white"}} className="text-center" >•Our Sponsors•</h3><br/>
            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px"}}>Platform Partner</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <a href="https://online.codingblocks.com/courses"><img src={codingBlock} className="img-fluid" style={{width: "300px"}} /></a>
              </div>
            </div>
            <hr class="line_1" />
            <br/>
            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Associate Partner</h4>
              <div className="col-sm-12 d-flex justify-content-center">
              <a href="geeksforgeeks.org"><img src={gfg} className="img-fluid" style={{width: "300px"}}/></a> 
              </div>
            </div>
            <hr class="line_1" />
            <br/>
            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Education Partner</h4>
              <div className="col-sm-12 d-flex justify-content-center">
              <a href="https://www.abnersecurity.com/"><img src={abner} className="img-fluid" style={{width: "300px"}}/></a> 
              </div>
            </div>
            {/*
             <hr class="line_1" />
            <br/>
            <div className="row d-flex justify-content-center">
                <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Associate Sponsors</h4>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-sm-12 col-lg-3 d-flex justify-content-center">
                <a href="https://www.notion.so/Matic-Network-Devfolio-Hackathon-Season-Prize-de8961d5eeff4780963749da0b75037c"><img src="https://res.cloudinary.com/devdemo/image/upload/v1635158734/codecell%20website%20gallery%20images/sponsersLogo/matic_kyaump.png" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
              <div className="col-sm-12 col-lg-3 offset-lg-1 d-flex justify-content-center">
                <a href="https://www.notion.so/Portis-by-Shapeshift-Devfolio-Hackathon-Season-Prize-e198dfd094a9411b8cd0f80d76a04a90"><img src="https://res.cloudinary.com/devdemo/image/upload/v1635158735/codecell%20website%20gallery%20images/sponsersLogo/portis_lrjp0c.png" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
              <div className="col-sm-12 col-lg-3 offset-lg-1 d-flex justify-content-center">
                <a href="https://www.notion.so/Tezos-Devfolio-Hackathon-Season-Prize-e90b6811b0df43e5a7dadf534fc000ff"><img src="https://res.cloudinary.com/devdemo/image/upload/v1635158735/codecell%20website%20gallery%20images/sponsersLogo/tezos_umfnhp.png" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div>
             <hr class="line_1" />
            <br/>

            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Technical Sponsor</h4>
              <div className="col-sm-12 d-flex justify-content-center">
              <a href="https://www.wolfram.com/language/#:~:text=Scalable%20for%20programs%20from%20tiny,first%20true%20computational%20communication%20language"> <img src="https://res.cloudinary.com/devdemo/image/upload/v1635158736/codecell%20website%20gallery%20images/sponsersLogo/wolfram_mflp4l.jpg" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div>
             <hr class="line_1" />
            <br/>
            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Education Sponseor</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <a href="https://www.endeavorcareers.com/"><img src="https://res.cloudinary.com/devdemo/image/upload/v1635158734/codecell%20website%20gallery%20images/sponsersLogo/endeavor_r8u2ng.png" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div>
             <hr class="line_1" />
            <br/>
            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Gaming Sponsor</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <img src="https://res.cloudinary.com/devdemo/image/upload/v1635158735/codecell%20website%20gallery%20images/sponsersLogo/PDC_Logo_18_jul-removebg-preview_rt5gxo.png" className="img-fluid" style={{width: "300px"}}/>
              </div>
            </div>
             <hr class="line_1" />
            <br/>
            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Counselling Sponsor</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <a href="https://learningedgeindia.com/"> <img src="https://res.cloudinary.com/devdemo/image/upload/v1635158734/codecell%20website%20gallery%20images/sponsersLogo/LearningEdge_xfrypb.jpg" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div>
             <hr class="line_1" />
            <br/>

            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Tshirt Partner</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <a href="https://www.dudeme.in/"><img src="https://res.cloudinary.com/devdemo/image/upload/v1635158735/codecell%20website%20gallery%20images/sponsersLogo/tshirt_n75ucs.png" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div>
             <hr class="line_1" />
            <br/>

            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Dessert Partner</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <img src="https://res.cloudinary.com/devdemo/image/upload/v1635158735/codecell%20website%20gallery%20images/sponsersLogo/MonkeyDough_z5zm6h.jpg" className="img-fluid" style={{width: "300px"}}/>
              </div>
            </div>
             <hr class="line_1" />
            <br/>

            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Community Partner</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <a href="https://ace.codeasylums.com/"><img src="https://res.cloudinary.com/devdemo/image/upload/v1635158733/codecell%20website%20gallery%20images/sponsersLogo/codeasylum_ffqlbu.jpg" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div>
             <hr class="line_1" />
            <br/>

            <div className="row d-flex justify-content-center">
              <h4 style={{fontSize: "20px", paddingTop: "30px"}}>Voucher Partner</h4>
              <div className="col-sm-12 d-flex justify-content-center">
                <a href="https://badelog.in/"><img src="https://res.cloudinary.com/devdemo/image/upload/v1635158733/codecell%20website%20gallery%20images/sponsersLogo/BadeLog.in_-_Logo_Black_orgtrs.png" className="img-fluid" style={{width: "300px"}}/></a>
              </div>
            </div> */}
            
          </div>
          </div> 
          </Container>
          <br/><br/>

          <div className="section rules" id="rules">
          <Container>
            <Row className="justify-content-between">
              <Col md="12">
                <h1 className="profile-title text-left">Rule & Regulations</h1>
                <h5 className="text-on-back">04</h5>
                <h4>
           Rules and regulations to be followed by participants:
           </h4>
           <br/>
           <h3>During registration:</h3>
           <ul>
             <li>Registrations beyond the registration date will not be entertained.</li>
             <li>Participants are encouraged to give valid information about themselves, gmail provided while registration should be the same as the one which will be used to register on hacker block site .</li>

           </ul>
           {/* <h3>After selection:</h3>
           <ul>
             <li>Selected participants need to confirm participation. Not doing so may lead to their spot being given to another participant.</li>
             <li>Any sort of bribery to the staff will not be entertained and can also lead to disqualification.</li>
           </ul> */}
           <h3>During the event:</h3>
           <ul>
             <li>The coding segment starts sharp at 11:00 AM and ends at 01:30 PM on 13/11/2021.</li>
             <li>Usage of expressions that are vulgar, obscene or derogatory in any manner is highly discouraged.</li>
             <li>Try to develop applications that do not deviate too much from the actual topic.</li>
             <li>Violation of copyrights, trademarks, or other such rights can lead to disqualification</li>
             <li>Copying solutions will lead to negative marking.</li>
             <li>Receiving assistance from an external party (those who aren’t part of the event) is prohibited.</li>
             <li>Participants are not allowed to use code written by them before the event.</li>
             <li>Participants are expected to stop coding when the allotted time is over.</li>
             <li>Anyone involved in malpractice will be disqualified.</li>
           </ul>
           <h3>After the results are announced:</h3>
           <ul>
             <li>The decision will be system generated, no arguments will be entertained.</li>
             <li>In case of the same score, ranking will be based on time.</li>
             <li>Prizes are subject to change in case of extreme situations. Accordingly, members of CESS and CodeCell and the college staff or members cannot be held responsible.</li>
           </ul>
              </Col>
            </Row>
          </Container>
        </div>
        </section>
        <Footer />
      </div>
    </>
  );
}
