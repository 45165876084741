import React from "react";
import classes from './clock.module.css'

const Clock = ({ timerDays, timerHours, timerMinutes, timerSeconds }) => {
  return (
    <>
        <section>
          <div className="clock">
            <section>
              <p>{timerDays}</p>
              <small className={classes['clock-text']}>Days</small>
            </section>
            <span>:</span>
            <section>
              <p>{timerHours}</p>
              <small className={classes['clock-text']}>Hours</small>
            </section>{" "}
            <span>:</span>
            <section>
              <p>{timerMinutes}</p>
              <small className={classes['clock-text']}>Minutes</small>
            </section>{" "}
            <span>:</span>
            <section>
              <p>{timerSeconds}</p>
              <small className={classes['clock-text']}>Seconds</small>
            </section>
          </div>
        </section>
    </>
  );
};

Clock.defaultProps = {
  timerDays: 10,
  timerHours: 10,
  timerMinutes: 10,
  timerSeconds: 10,
};

export default Clock;
